#maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--dark-blue);
    padding-top: 5rem;    
    font-family: Montserrat;

    height: 70vh;
}

.maintenance-text h1, .maintenance-text h2{
    text-align: center;
}