body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner-wrapper{
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: relative;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
}

.spinner::before,
.spinner:after{
  content: "";
  position: absolute;
  border-radius: 50%;
}

.spinner:before {
  width: 100%;
  height: 100%;
  background-image:linear-gradient(90deg, #21974E 0%,#37357B 100% );
  animation: spin .5s infinite linear;
}

.spinner:after {
  width: 97%;
  height: 97%;
  background: center / 70% no-repeat url("/src/logo_centralizada.webp"), #f8f8f8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}