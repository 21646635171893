* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

button{
    border: none;
    background: transparent;
}

@font-face {
    font-family: "My Custom Font";
    src: url("../fonts/Amithen.ttf") format("truetype");
}

:root{
    --dark-green: rgb(33,151,78);
    --new-dark-green: rgb(0,106,39);
    --light-green: rgb(143,199,74);
    --new-light-green: rgb(148,170,23);
    --dark-blue: rgb(55,53,123);
    --yellow-gold: rgb(251,178,74);
    --new-yellow-gold: rgb(232,193,14);

    --main-font: "Baloo Tammudu 2", sans-serif;
    --second-font: "Roboto", "Open Sans", "Helvetica", "Segoe UI", "Arial", sans-serif;
    --third-font: "My Custom Font", "Baloo Tammudu 2", sans-serif
}

body{
    font-family: var(--main-font);
    font-size: medium;
    background: #fff;
}

p {
    line-height: 1.6;
}

.container{
    padding: 2.5rem;
}

.divider-1{
    height: 1rem;
    background: linear-gradient(270deg, var(--new-dark-green) 0%, var(--dark-blue) 100%) 
}

.divider-2{
    height: 1px;
    background: linear-gradient(270deg, #fff, #bbb, #fff); 
}

.divider-2-v{ 
    width: 1px;
    background: linear-gradient(#fff, #bbb, #fff); 
}

.section-title{
    font-size: 2rem;
    color: var(--dark-blue);
    line-height: 2rem;
    margin-top: 3rem;
    font-weight: 500;
}

/* ==================== PAGE TITLE ======================= */

.page-title {
    display: flex;
    align-items: baseline;
    justify-content: center;
    height: 10rem;
    line-height: 11.5rem;
    color: #f8f8f8;
    font-size: 1.5rem;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) , url("../lavoura.jpg") no-repeat;
    background-size: cover;
    background-position: center center; 
}

.imagenet-widget-tabela{
    visibility: hidden;
    opacity: 0;
    position: fixed;
}