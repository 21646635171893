.main-footer{ 
    display: grid;
    background: #f8f8f8;
    color: var(--dark-blue);
}

.footer-grid{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* .links-uteis{
    display: flex;
    align-items: center;
    margin-top: 2rem;
    font-size: 1.2rem;
    order: 1;
} */

/* .links-uteis a:hover{
    color: var(--dark-green);
} */

.main-footer img{
    width: 8rem;
    order: 0;
    margin-top: 1rem;
}

.addresses{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    gap: 1rem;
}

.address{
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
}

.address h3{
    margin-bottom: 1rem;
}

.address p {
    margin-bottom: 0.5rem;
}

.address p svg{
    align-self: center; 
    /* color: #262626; */
    color: rgb(55,53,123);
}


/* SOCIAL MEDIA */
.social-wrapper{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.social-wrapper img{
    height: 3.5rem;
} 

.social-wrapper .text {
    /* margin-left: 2rem; */
    font-weight: bold;
    font-size: large;
    /* color: #9510B8; */
    color: var(--dark-blue);
}

.social-media{
    display: flex;
    gap: 0.5rem;
}

.instagram,
.facebook,
.youtube{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 10rem;
    background: var(--dark-blue);
}

.whatsapp{
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: auto;
    border-radius: 1000px;
    background: #25d366;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 50%);
    padding: 7px;

    cursor: pointer;
    display: flex;

    z-index: 1002;
}

/* .whatsapp span{
    visibility: hidden;
    opacity: 0;
} */

/* 
.whatsapp:hover{
    width: 15rem;
} */


.whatsapp img{
    width: 2.5rem;
    align-items: center;
    justify-content: center;
}

@media (min-width: 767px){

    .main-footer{
    grid-template-columns: 1.2fr 1.8fr 0.8fr;
    /* height: 16rem; */
    background: #f8f8f8;
    padding-bottom: 1.5rem;
    }

    .main-footer img{
        width: 14rem;
    }

    .addresses{
        flex-direction: row;
    }

    .addresses .address{
        width: 50%;
        margin-left: unset;
    }

    .address h3{
        margin-bottom: 1.5rem;
        margin-left: unset;
    }

    /* .links-uteis{
        align-items: flex-start;
        margin-left: 6rem;
        order: 0;
    } */
}